@media only screen and (min-width: 600px) {
    html{
        scroll-behavior: smooth;
    }
    
    .navbar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        position: fixed;
        padding: 50px 30px 20px;
        background-color: transparent;
        width: 100%;
    }

    .navbar-active {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        gap: 50px;
        padding: 50px 30px 20px;
        background-color: #F8F7FE;
        width: 100%;
        height: 100vh;
        z-index: 2;
    }

    .navButtons {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 50px;
        height: 100vh;
    }
    
    .container {
        display: flex;
        flex-direction: column;
        padding: 50px 30px;
    }

    .projectTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-wrap: pretty;
        text-align: center;
        width: 100%;
    }

    .about-projects {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        width: 100%;
    }

    .cards {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }

    .pictures-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding-top: 50px;
    }

    .pictures {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .nav-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 100px;
        width: 100%;
    }

    .targetAudience {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
    }

    .targetAudience-tags {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #005FED;
        padding: 10px 15px;
        width: fit-content;
    }

    .teckStack {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }

    .teckStack-tags {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        padding: 10px 15px;
        width: fit-content;
    }

    .roles {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }

    .roles-tags {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #005FED;
        padding: 10px 15px;
        width: fit-content;
    }
}