  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    html{
        scroll-behavior: smooth;
    }
    
    .navbar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        position: fixed;
        padding: 50px 30px 20px;
        background-color: transparent;
        width: 100%;
    }
    
    .navbar-active {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        gap: 50px;
        padding: 50px 30px 20px;
        background-color: #F8F7FE;
        width: 100%;
        height: 100vh;
        z-index: 2;
    }
    
    .navbar-scrolled {
        color: #005FED;
    }
    
    .navbar-unscrolled {
        color: #F8F7FE;
    }
    
    .navButtons {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 50px;
        height: 100vh;
    }
    
    .hero {
        display: flex;
        flex-direction: column;
        padding: 50px 119px;
        align-items: center;
        background-color: #005FED;
        height: auto;
    }
    
    .hero-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        gap: 30px;
    }
    
    .carousel-container {
        /* overflow: hidden; */
        position: relative;
        top: 30px;
        height: 100%;
        width: 100%;
    }
    
    .carousel-track {
        display: flex;
        position: absolute;
        left: 0;
        justify-content: center;
        align-items: center;
        gap: 16px;
        width: 200%;
        height: 100%;
        animation: slide 20s linear infinite;
    }
    
    .carousel-card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 20px;
    }
    
    .carousel-background {
        display: flex;
        background-color: black;
        width: 100%;
    }
    
    @keyframes slide {
        0% {
            left: 0;
        }
    
        100% {
            left: -100%;
        }
    }
    
    .whoami {
        display: flex;
        flex-direction: column;
        padding: 50px 119px;
        align-items: center;
    }
    
    .whoami-left {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: stretch;
        text-align: justify;
        gap: 30px;
    }
    
    .whoami-right {
        display: flex;
        flex-direction: column;
        width: auto;
    }
    
    .whatdoido {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .whatdoido-left {
        display: flex;
        flex-direction: column;
        width: auto;
        padding: 50px 119px 50px 119px;
        gap: 20px;
    }
    
    .whatdoido-examples {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .whatdoido-examples-buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
    
    .whatdoido-right {
        display: flex;
        flex-direction: column;
        width: auto;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
    }
    
    .whatdoido-right-selection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
        padding: 50px 119px;
    }
    
    .whatdoido-right-selection-cedarcms {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 50px;
        padding: 50px 119px;
        background: center / contain no-repeat url('../../Assets/Digital\ Project\ Background/CEDaRCMS.png');
    }
    
    .whatdoido-right-selection-citypoems {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 50px;
        padding: 50px 119px;
        background: center / contain no-repeat url('../../Assets/Digital\ Project\ Background/CPP.png');
    }
    
    .whatdoido-right-selection-sxg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 50px;
        padding: 50px 119px;
        background: center / contain no-repeat url('../../Assets/Digital\ Project\ Background/SXG.png');
    }
    
    .whatdoido-right-selection-hs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 50px;
        padding: 50px 119px;
        background: center / contain no-repeat url('../../Assets/Digital\ Project\ Background/HS.png');
    }
    
    .skills {
        display: flex;
        flex-direction: column;
        padding: 50px 119px;
        gap: 50px;
    }
    
    .skills-top {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
    
    .skills-top-buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
    
    .skills-bottom {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    
    .skills-bottom-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    
    .text-stretcher {
        flex: 1 0 0;
        display: flex;
        align-items: center;
        align-self: stretch;
        width: 100%;
        height: 100%;
        color: #403D3A;
        font-weight: 500;
        text-align: start;
        /* border: green solid 2px; */
    }
    
    .footer {
        display: flex;
        flex-direction: row;
        padding: 50px 119px 0px 0px;
        gap: 30px;
    }
    
    .footer-left {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
    }
    
    .footer-right {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-bottom: 50px;
        gap: 30px;
    }
    
    .links {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        justify-content: flex-start;
    }
  }